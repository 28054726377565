<template>
    <div class="commission-terms">
        <h1>How to know the terms of commissions of advertising platforms?</h1>
        <p>In the "Commission" section, when you select an advertising platform, the commission terms for enrollments will
            be displayed.
        </p>
    </div>
</template>
<script>
export default {
    name: "howToKnowTermsOfCommissions",
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
        }
    }
}
</script>
<style lang="scss" scoped>
.commission-terms {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h1 {
        line-height: normal;
    }

    ol,
    ul {
        list-style-position: inside;
    }
}
</style>